import { COMBINE_EVENTS_EQUATION, Metrics } from "enum/enums";
import React, { useContext, useMemo } from "react";
import styles from "./AnalyticCoreCards.module.scss";
import AnalyticsCard from "atoms/analyticsCard/AnalyticsCard";
import { CombineEvents, getEventCountSum, kpiCalculation, sortEventData } from "lib/utils";
import AnalyticsPropsContext from "context/AnalyticsPropsContext";
import { AnalyticsPropsContextInterface } from "interface/interface";
// import PoweredSalesModal from "component/poweredSalesModal/PoweredSalesModal";

const AnalyticCoreCards = () => {
	const {
		swanPoweredSales,
		tryOnSuccessData,
		measurementSuccessData,
		sizingData,
		datePickerValue,
		distinctProductViewData,
		orderData,
		tryOnFailedData,
	} = useContext(AnalyticsPropsContext) as AnalyticsPropsContextInterface;
	const SWAN_INFLUENCE_TO_SALES_DATA = useMemo(() => {
		const value =
			kpiCalculation(
				swanPoweredSales?.price?.length,
				orderData?.totalOrders,
				true,
			) ?? 0;
		return value || 0;
	}, [swanPoweredSales, orderData]);

	// const AOVDATA = useMemo(() => {
	// 	const value = kpiCalculation(swanPoweredSales?.totalSales?.totalOrderAmount, swanPoweredSales?.totalSales?.totalOrderCount, true);
	// 	return value || 0;
	// }, [swanPoweredSales]);
	
	return (
		<div className={`${styles.cardList} flex-wrap`}>
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.SWAN_POWERED_SALES}
					value={getEventCountSum(swanPoweredSales?.price) ? `${getEventCountSum(swanPoweredSales?.price)} USD`:  0}	
					showGraph
					data={sortEventData(swanPoweredSales?.price)}
					datePickerValue={datePickerValue}
					InfoIcon
					InfoIconTitle="Revenue from purchases which were completed with Swan."
				/>
			</div> 
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.SWAN_INFLUENCE_TO_SALES}
					value={SWAN_INFLUENCE_TO_SALES_DATA || 0}
					datePickerValue={datePickerValue}
					data={CombineEvents(
						orderData?.data,
						CombineEvents(tryOnSuccessData, measurementSuccessData, COMBINE_EVENTS_EQUATION.ADD),
						COMBINE_EVENTS_EQUATION.DIVIDE,
					)}
					showGraph
					InfoIcon
					InfoIconTitle="Rate at which a Swan sizing suggestion or Try-On led to a sale."
				/>
			</div>
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.AOV}
					value={ orderData?.aov|| 0}
					showGraph
					data={orderData?.data}
					datePickerValue={datePickerValue}
					InfoIcon
					InfoIconTitle="Average amount spent per order."
				/>
			</div>
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.SIZING_SUGGESTIONS}
					data={sizingData}
					datePickerValue={datePickerValue}
					showGraph
					InfoIcon
					value={getEventCountSum(sizingData)||0}
					InfoIconTitle="Total number of sizing suggestions which were generated."
				/>
			</div>
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.TRY_ONS}
					data={CombineEvents(tryOnSuccessData, tryOnFailedData, COMBINE_EVENTS_EQUATION.ADD)}
					datePickerValue={datePickerValue}
					showGraph
					value={getEventCountSum(tryOnSuccessData) + getEventCountSum(tryOnFailedData) || 0}
					InfoIcon
					InfoIconTitle="Total number of Try-Ons generated."
				/>
			</div>
			<div className={`${styles.card}`}>
				<AnalyticsCard
					title={Metrics.SIZING_SUGGESTIONS_PER_USER}
					data={CombineEvents(sizingData, distinctProductViewData, COMBINE_EVENTS_EQUATION.DIVIDE)}
					datePickerValue={datePickerValue}
					value={kpiCalculation(getEventCountSum(sizingData),getEventCountSum(distinctProductViewData))||0}
					showGraph
					InfoIcon
					InfoIconTitle="Average number of sizing suggestions which were generated for each user."
				/>
			</div>
			{/* <div className={`${styles.card}`}>
        <AnalyticsCard
          title="This analysis is locked"
          showGraph
            InfoIcon
          InfoIconTitle="This data analysis is lock for now"
          locked
        />
      </div> */}

			
		</div>
	);
};

export default AnalyticCoreCards;
