import React, { useEffect, useState } from "react";
import styles from "./Analytics.module.scss";
import LoadingScreen from "component/LoadingScreen";
import { DATE_PICKER_VALUE, FETCH_LIST_TYPE, METRICS_KEY } from "enum/enums";
import { fetchEventsData, getOrdersDetails } from "services/analyticService";
import { FULFILLED, TODAY_DATE } from "lib/Constants";
import AnalyticsChild from "./AnalyticsChild";
import CustomDialog from "component/CustomDialog";
import CalenderModal from "component/calenderModal/CalenderModal";
import DateFilter from "component/dateFilter/DateFilter";
import { extractDataFromEvents, formatDateForMetric, getDate, sortEventData } from "lib/utils";
import AnalyticsPropsContext from "context/AnalyticsPropsContext";

const Analytics = () => { 
	const [datePickerValue, setDatePickerValue] = useState<DATE_PICKER_VALUE>(DATE_PICKER_VALUE.THIRTY_DAYS);
	const [loading, setLoading] = useState<boolean>(true);
	const [measurementSuccessData, setMeasurementSuccessData] = useState<any[]>([]);
	const [measurementFailedData, setMeasurementFailedData] = useState<any[]>([]);
	const [scanStartedData, setScanStartedData] = useState<any[]>([]);
	const [open, setOpen] = useState(false);
	const [startDate, setStartDate] = useState<Date>(TODAY_DATE);
	const [endDate, setEndDate] = useState<Date>(TODAY_DATE);
	const [swanPoweredSales, setSwanPoweredSales] = useState<any>();
	const [sizingData, setSizingData] = useState<any[]>([]);
	const [distinctProductViewData, setDistinctProductViewData] = useState<any[]>([]);
	const [phoneDetectAutoData, setPhoneDetectAutoData] = useState<any[]>([]);
	const [cameraActivationFailed, setCameraActivationFailed] = useState<any[]>([]);
	const [phoneDetectManualData, setPhoneDetectManualData] = useState<any[]>([]);
	const [tryOnShareData, setTryOnShareData] = useState<any[]>([]);
	const [tryOnSuccessData, setTryOnSuccessData] = useState<any[]>([]);
	const [tryOnFailedData, setTryOnFailedData] = useState<any[]>([]);
	const [scanCompletionTimeData, setScanCompletionTimeData] = useState<any[]>([]);
	const [tryOnStartedData, setTryOnStartedData] = useState<any[]>([]);
	const [tryOnCompletionTimeData, setTryOnCompletionTimeData] = useState<any[]>([]);
	const [ctaClickData, setCtaClickData] = useState<any[]>([]);
	const [orderData, setOrderData] = useState<Record<string,any>>({aov:0,data:[]});
	

	const handleShowModal = () => {
		setOpen(!open);
	};
	const fetchAllList = async (custom = false) => {
		setLoading(true);
		try {
			let afterDate = formatDateForMetric(datePickerValue);
			let beforeDate = "";
			if (custom) {
				afterDate = getDate(startDate);
				beforeDate = endDate ? getDate(endDate) : "";
			}
			const data = [
				{ event: FETCH_LIST_TYPE.CTA_METRICS, beforeDate, afterDate },
				{ event: FETCH_LIST_TYPE.GROUP_TWO_METRICS, beforeDate, afterDate },
				{ event: FETCH_LIST_TYPE.GROUP_THREE_METRICS, beforeDate, afterDate },
				{ event: FETCH_LIST_TYPE.GROUP_FOUR_METRICS, beforeDate, afterDate },
				{ event: FETCH_LIST_TYPE.SALES_METRICS, beforeDate, afterDate },

			];
			const fetchPromisesCombined = data.map((el: any) => fetchEventsData(el));
			// fetchPromisesCombined.push(getProductsSupported(afterDate));
			fetchPromisesCombined.push(getOrdersDetails(afterDate));
			// fetchPromisesCombined.push(fetchEventsData({ event: FETCH_LIST_TYPE.SALES_METRICS, afterDate: afterDate, beforeDate: beforeDate }));

			const resolvedResults = await Promise.allSettled(fetchPromisesCombined);
			resolvedResults?.forEach((result, index) => {
				if (result?.status === FULFILLED) {
					const data: any = result?.value?.data;
					switch (index) {
						case 0: {
							Object.entries(extractDataFromEvents(data?.result)).forEach(([key, value]: [key: string, value: any[]]) => {
								switch (key) {
									case METRICS_KEY.CTA_CLICKS: {
										setCtaClickData(sortEventData(value));
										break;
									}
									case METRICS_KEY.MEASUREMENT_SUCCESS: {										
										setMeasurementSuccessData(sortEventData(value));
										break;
									}
									case METRICS_KEY.PHONE_DETECTIONS_AUTO: {
										setPhoneDetectAutoData(sortEventData(value));
										break;
									}
									case METRICS_KEY.CAMERA_ACTIVATION_FAILED: {
										setCameraActivationFailed(sortEventData(value));
										break;
									}
									case METRICS_KEY.PHONE_DETECTIONS_MANUAL: {
										setPhoneDetectManualData(sortEventData(value));
										break;
									}
									case METRICS_KEY.MEASUREMENT_FAILED: {
										setMeasurementFailedData(sortEventData(value));
										break;
									}
								}
							});
							break;
						}
						case 1: {
							Object.entries(extractDataFromEvents(data?.result)).forEach(([key, value]: [key: string, value: any[]]) => {
								switch (key) {
									case METRICS_KEY.FITVIEW_SIZING: {
										setSizingData(sortEventData(value));
										break;
									}
									case METRICS_KEY.SCAN_STARTED: {
										setScanStartedData(sortEventData(value));
										break;
									}
									case METRICS_KEY.DISTINCT_PRODUCT_REQUEST: {
										setDistinctProductViewData(sortEventData(value));
										break;
									}
								}
							});
							break;
						}
						case 2: {
							Object.entries(extractDataFromEvents(data?.result)).forEach(([key, value]: [key: string, value: any[]]) => {
								switch (key) {
									case METRICS_KEY.TRY_ON_SUCCESS: {
										setTryOnSuccessData(sortEventData(value));
										break;
									}
									case METRICS_KEY.TRY_ON_FAILED: {
										setTryOnFailedData(sortEventData(value));
										break;
									}
									case METRICS_KEY.TRY_ON_STARTED: {
										setTryOnStartedData(sortEventData(value));
										break;
									}
									case METRICS_KEY.TRY_ON_SHARE: {
										setTryOnShareData(sortEventData(value));
										break;
									}
								}
							});
							break;
						}
						case 3: {
							Object.entries(extractDataFromEvents(data?.result)).forEach(([key, value]: [key: string, value: any[]]) => {
								switch (key) {
									case METRICS_KEY.TRY_ON_COMPLETION_TIME: {
										setTryOnCompletionTimeData(sortEventData(value));
										break;
									}
									case METRICS_KEY.SCAN_COMPLETION_TIME: {
										setScanCompletionTimeData(sortEventData(value));
										break;
									}
								}
							});
							break;
						}
						case 4: {
							setSwanPoweredSales(extractDataFromEvents(data?.result));
							break;
						}
						// case 5: {
						// 	// console.log("---- data 3", data)
						// 	// setSwanPoweredSales(data.result);
						// 	break;
						// }
						default: {		
							// console.log("--- data 1", data)					
							setOrderData({aov:data?.averageOrderValue||0,totalOrders:data?.totalOrders||0,data:sortEventData(extractDataFromEvents(data, "single"))});
						}
					}
				}
			});
		} catch (err) {
			console.log("error=", err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (datePickerValue !== DATE_PICKER_VALUE.CUSTOM) {
			fetchAllList();
		}
	}, [datePickerValue]);

	return (
		<div className={`${styles.listMain}`}>
			<CustomDialog open={open} handleShowModal={handleShowModal}>
				<CalenderModal
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					handleShowModal={handleShowModal}
					setDatePickerValue={setDatePickerValue}
					fetchAllList={fetchAllList}
				/>
			</CustomDialog>
			<div className={`${styles.main}`}>
				<div className={`flex ${styles.dateSelect}`}>
					<DateFilter
						disabled={loading}
						setDatePickerValue={setDatePickerValue}
						datePickerValue={datePickerValue}
						handleShowModal={handleShowModal}
						startDate={getDate(startDate)}
						endDate={getDate(endDate)}
					/>
				</div>

				{loading ? (
					<LoadingScreen height={false} />
				) : (
					<div className={`${styles.listMain__cardMain} flex-wrap`}>
						<AnalyticsPropsContext.Provider
							value={{
								datePickerValue,
								measurementSuccessData,
								scanStartedData,
								swanPoweredSales,
								sizingData,
								distinctProductViewData,
								measurementFailedData,
								phoneDetectAutoData,
								phoneDetectManualData,
								tryOnShareData,
								tryOnSuccessData,
								scanCompletionTimeData,
								tryOnCompletionTimeData,
								ctaClickData,
								tryOnFailedData,
								tryOnStartedData,
								orderData,
								cameraActivationFailed
							}}
						>
							<AnalyticsChild />
						</AnalyticsPropsContext.Provider>
					</div>
				)}
			</div>
		</div>
	);
};

export default Analytics;
